import React, { useEffect, useState } from 'react';
import { Text, Image } from 'theme-ui';
import styled from '@emotion/styled';
import Layout from '../components/layout';

import Masonry, { ResponsiveMasonry } from 'react-responsive-masonry';
import { SRLWrapper } from 'simple-react-lightbox';

const Img = styled(Image)`
  cursor: pointer;
`;

const options = {
  settings: {
    // overlayColor: 'rgb(25, 136, 124)',
    autoplaySpeed: 1500,
    transitionSpeed: 900,
  },
  buttons: {
    // backgroundColor: '#1b5245',
    iconColor: 'rgba(126, 172, 139, 0.8)',
  },
  caption: {
    showCaption: false,
  },
  thumbnails: {
    showThumbnails: false,
  },
};

const Gallery = () => {
  const [gallery, setGallery] = useState([]);
  const galleryImages = [];

  useEffect(() => {
    for (let i = 1; i <= 40; i++) {
      galleryImages.push(`http://d3kvuzlw39or84.cloudfront.net/uvc-${i}.jpeg`);
    }

    setGallery(galleryImages);
  }, []);

  return (
    gallery.length !== 0 && (
      <Layout>
        <Text
          as="h1"
          width="100%"
          my={[4]}
          // fontSize={[5]}
          sx={{ textAlign: 'center', textTransform: 'uppercase' }}
        >
          Gallery
        </Text>
        <ResponsiveMasonry
          columnsCountBreakPoints={{
            350: 1,
            750: 2,
            900: 3,
            1280: 4,
            1440: 5,
            1680: 6,
          }}
        >
          <Masonry>
            {gallery.map((image) => (
              <SRLWrapper options={options}>
                <Img
                  key={image}
                  src={image}
                  alt={`uvc-${image}`}
                  style={{
                    padding: '0.6rem 0',
                    margin: '0 auto',
                    // maxHeight: '500px',
                    // width: '100%',
                    maxWidth: '300px',
                    display: 'block',
                  }}
                />
              </SRLWrapper>
            ))}
          </Masonry>
        </ResponsiveMasonry>
      </Layout>
    )
  );
};

export default Gallery;
